[data-sidebar-position="fixed"][data-layout="vertical"] {
    .nav-header {
        position: fixed;
        // left: auto;
        border-top-left-radius:0;
        border-top-right-radius:0;
    }

    .dlabnav {
        position: fixed;
        // z-index: 0;
        .dlabnav-scroll {
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
        }
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
    }

    .menu-toggle {
        .dlabnav {
            position: fixed;
        }
    }
}
[data-layout="vertical"]{
	.nav-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .dlabnav {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
    }
}
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] {
    
	.dlabnav {
        position: fixed;
		
    }
	.menu-toggle{
		.dlabnav {
			position: fixed;
			z-index: 99999;
			height: calc(100% - 80px);
			left: calc(calc(100% - 1199px) / 2);
			box-shadow: 20px 20px 20px 0 rgba(0, 0, 0, 0.1);
		}
	}
}



