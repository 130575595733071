

.asColorPicker-dropdown {
    max-width: 26rem;
}
.asColorPicker-trigger {
    overflow: hidden;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.asColorPicker-trigger {
    border  : 0 none;
    height  : 100%;
    position: absolute;
    right: -8px;
    top     : 0;
    width   : 2.1875rem;
}
[direction="rtl"] .asColorPicker-trigger{
	left: 0;
	right: auto;
}
.asColorPicker-clear {
    display        : none;
    position       : absolute;
    right          : 1rem;
    text-decoration: none;
    top            : .5rem;
}



.daterangepicker {
    td.active {
        background-color: var(--primary);
		color:$white!important;

        &:hover {
            background-color: var(--primary);
        }
    }

    button.applyBtn {
        background-color: var(--primary);
        border-color: var(--primary);
    }
}

.datepicker {
    &.datepicker-dropdown {
		padding:1rem;
        border-radius: 0.0625rem;
        border: 0.0625rem solid $gallery;
		cursor:pointer;

        td.day, th.next, th.prev {
            height: 33px;
            width: 33px !important;
            //padding: 0;
            text-align: center;
            font-weight: 300;
			//font-size:12px;
            border-radius: $radius-sm;

            &:hover {
                @extend %gradient-9;
				//font-size:12px;
				
                color: var(--primary);
				background:linear-gradient(to right, #f9f9f9 0%, #f9f9f9 100%) !important;
            }
        }

        th.datepicker-switch, th.next, th.prev {
            font-weight: 500;
			font-size:14px;
            color: $heading;
			text-align:center;
        }

        th.dow {
            font-weight: 300;
        }
    }
    table {

        

        tr td.selected, tr td.active.active {
            @extend %gradient-9;
            //box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243,30,122,0.3);
            border: 0;
			background:linear-gradient(to right, #f93a0b 0%, #f93a0b 100%) !important;
			color:$white;
        }

        tr {
            td.today {
                @extend %gradient-9;
                box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243,30,122,0.3);
                color     : #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }

            td.today.disabled {
                @extend %gradient-9;
                box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243,30,122,0.3);
                color     : #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243,30,122,0.3);
                    color     : #ffffff;
                }
            }
        }
    }
}


.picker {
    &__select--month, &__select--year {
        height: 2.5em;
    }

    &__input {
        background-color: transparent !important;

        @at-root [data-theme-version="dark"] & {
            background-color: transparent !important;
            border: 0.0625rem solid $d-border;
        }
    }
}

.asColorPicker-wrap{
	.form-control{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}
.daterangepicker td.available:hover, .daterangepicker th.available:hover{
		color:var(--primary);
		background-color:#f9f9f9;
}
.daterangepicker .drp-calendar.left{
		padding:1rem 1rem;
}
.daterangepicker .calendar-table th, .daterangepicker .calendar-table td{
	width:33px;
	height:33px;
	border-radius:$radius-sm!important;
	color:#B5B5C3;
}
.daterangepicker td.in-range{
	background-color:transparent;
	color:#5E6278!important;
}
.daterangepicker .calendar-table th{
	font-size:14px;
	font-weight:500;
	color:#3F4254;
}
button.cancelBtn.btn.btn-sm.btn-inverse{
	background-color:#f9f9f9;
}
.daterangepicker{
	box-shadow:0px 0px 50px 0px rgba(82, 63, 105, 0.15);
	border:0;
}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span{
	border-width:0 1.9px 1.9px 0;
	border-color:$light;
}

.picker-data{
	.color-time-picker{
		.react-time-picker{
			width:100%;
		}
		.react-time-picker__wrapper{
			border:0;
			color:#7e7e7e;
			.react-time-picker__clear-button{
				display:none;
			}
			.react-time-picker__inputGroup{
				border:1px solid #b7b7b7;
				border-width:0 0 1px 0;
				padding: .35rem 0rem
			}
			.react-time-picker__inputGroup__input{
				color:#7e7e7e;
			}
			.react-time-picker__inputGroup__amPm{
				color:#7e7e7e;
				appearance: none;
			}
			.react-time-picker__clock-button{
				background:$primary;
				color: #b7b7b7;
				padding: .532rem .75rem;
				border-radius: 0 0.75rem 0.75rem 0;
				min-width: 3.125rem;
				text-align: center;
				line-height: 1;
				svg{
					stroke: #fff;
					display:inline-block;
				}
			} 
		}
		.react-clock__face{
			background: #ededed;
			padding: 0.625rem 0.625rem;	
			border: 0.625rem solid #ededed;
		}
		.react-clock__hand__body{
			background-color: $primary;
		}
		.react-time-picker__clock{
			box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);
			border-radius:4px;
			border: 0 !important;
		}
		&.style-1{
			.react-time-picker__wrapper{
				.react-time-picker__clock-button{
					display: none;
					
				} 
			}
			
		}
		
	}
}

.def-check {
    &.example {
        .as_colorpicker  {
            width: 30%;
        }
    }
}
.color-gradian-tixia{
    .gpw{
        padding: 0rem;    
    }
    .gpw .trigger .inner{
        height: 1.8rem;
        width: 6.8rem;
    }

}