.form-check-input{
	&:checked {
		background-color: var(--primary);
		border-color: var(--primary);	
		background-size: cover;
	}
	&:focus {
		border-color: var(--primary);
		box-shadow: var(--rgba-primary-1);
	}
}